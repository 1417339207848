import { Doughnut } from 'vue-chartjs';
// const { reactiveProp } = mixins;

export default {
  extends: Doughnut,

  props: ['data', 'options', 'loaded'],

  mounted() {
    this.renderChart(this.data, this.options);

    this.registerEvents();
  },

  methods: {
    registerEvents() {
      this.$on('Doughnut:update', (event, data) => {
        this.updateChart();
        // this.renderChart(this.data, this.options);
      });
    },

    updateChart() {
      this._chart.update();
    }
  },

  watch: {
    data() {
      this._chart.destroy();
      this.renderChart(this.data, this.options);
    }
  }
};
