<template>
  <div class="chart-container" v-loading="loading">
    <div class="flex">
      <el-button class="m-r-1" type="primary" plain @click.prevent="requestChartData">Hole Statistic</el-button>

      <el-date-picker
        v-model="dateRange"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="-"
        :start-placeholder="format(new Date(form.start), 'yyyy-MM-dd')"
        :end-placeholder="format(new Date(form.end), 'yyyy-MM-dd')"
        @change="onChangeDateRange"
        style="flex: 1 1 auto;"
      ></el-date-picker>
    </div>

    <DoughnutChart
      class="chart"
      :data="chartData"
      :options="chartOptions"
      :loaded="dataLoaded"
      :width="400"
      :height="400"
      v-if="dataLoaded"
    />
  </div>
</template>

<script>
import { endOfYear, format, startOfYear } from 'date-fns';
import DoughnutChart from '@/components/Chart/doughnut-chart';
import * as TimetrackerService from '@/services/timetracker';

export default {
  name: 'ChartCustomerWorkload',

  components: {
    DoughnutChart
  },

  data() {
    return {
      loading: false,
      form: {
        start: format(startOfYear(new Date()), 'yyyy-MM-dd HH:mm'),
        end: format(endOfYear(new Date()), 'yyyy-MM-dd HH:mm')
      },
      dataLoaded: true,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              'rgba(255, 99, 132, 0.7)',
              'rgba(54, 162, 235, 0.7)',
              'rgba(255, 206, 86, 0.7)',
              'rgba(75, 192, 192, 0.7)',
              'rgba(153, 102, 255, 0.7)',
              'rgba(255, 159, 64, 0.7)',
              'rgba(26, 188, 156,1.0)',
              'rgba(142, 68, 173,1.0)',
              'rgba(231, 76, 60,1.0)',
              'rgba(211, 84, 0,1.0)',
              'rgba(241, 196, 15,1.0)',
              'rgba(41, 128, 185,1.0)',
              'rgba(252, 92, 101,1.0)',
              'rgba(75, 101, 132,1.0)',
              'rgba(43, 203, 186,1.0)',
              'rgba(32, 191, 107,1.0)',
              'rgba(56, 103, 214,1.0)',
              'rgba(69, 170, 242,1.0)',
              'rgba(247, 183, 49,1.0)',
              'rgba(0, 206, 201,1.0)',
              'rgba(232, 67, 147,1.0)',
              'rgba(214, 48, 49,1.0)'
            ],
            borderWidth: 2
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      dateRange: []
    };
  },

  mounted() {
    this.init();

    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case 'tracking/SET_TIMETRACKER':
          this.requestChartData();
          break;
      }
    });
  },

  methods: {
    format,

    init() {
      this.requestChartData();

      this.dateRange[0] = new Date(this.form.start);
      this.dateRange[1] = new Date(this.form.end);
    },

    onChangeDateRange(value) {
      this.form.start = format(value[0], 'yyyy-MM-dd HH:mm');
      this.form.end = format(value[1], 'yyyy-MM-dd HH:mm');
    },

    requestChartData() {
      this.loading = true;
      this.dataLoaded = false;

      TimetrackerService.getWorkloadChartForProjects(this.form)
        .then(response => {
          this.loading = false;

          const { data } = response;
          if (data) {
            if (data.labels) {
              this.chartData.labels = data.labels;
            }
            if (data.datasets) {
              this.chartData.datasets[0].data = data.datasets.data;
            }
            this.dataLoaded = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.dataLoaded = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  min-height: 30rem;

  .chart {
    margin-top: 3rem;
  }
}
</style>
